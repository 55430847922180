<template>
  <svg width="215" viewBox="0 0 215 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 4790">
      <g id="Group">
        <path id="Vector" d="M40.0828 44.0771H42.9606C45.034 44.0771 46.4615 45.2204 46.4615 46.9874C46.4615 48.7543 45.034 49.8976 42.9606 49.8976H40.0828V44.0771ZM42.8926 48.7855C44.1502 48.7855 44.9773 48.0995 44.9773 46.9874C44.9773 45.8752 44.1502 45.1789 42.8926 45.1789H41.5557V48.7855H42.8926Z" fill="#F36F28"/>
        <path id="Vector_2" d="M56.9984 48.6503H54.0526L53.4861 49.8976H51.9792L54.8004 44.0771H56.2507L59.0831 49.8976H57.5423L56.9758 48.6503H56.9984ZM56.5339 47.6318L55.5255 45.4075L54.5172 47.6318H56.5226H56.5339Z" fill="#F36F28"/>
        <path id="Vector_3" d="M66.1074 45.1789H64.0793V44.0771H69.6084V45.1789H67.5803V49.8976H66.1074V45.1789Z" fill="#F36F28"/>
        <path id="Vector_4" d="M79.6007 48.6503H76.6549L76.0884 49.8976H74.5815L77.4027 44.0771H78.8529L81.6854 49.8976H80.1446L79.5781 48.6503H79.6007ZM79.1362 47.6318L78.1278 45.4075L77.1195 47.6318H79.1249H79.1362Z" fill="#F36F28"/>
        <path id="Vector_5" d="M101.4 49.8979V46.4057L99.5193 49.2847H98.8622L97.0041 46.4888V49.9083H95.6218V44.0879H96.8341L99.2134 47.7049L101.547 44.0879H102.748L102.771 49.9083H101.389L101.4 49.8979Z" fill="#F36F28"/>
        <path id="Vector_6" d="M113.819 48.6503H110.873L110.306 49.8976H108.8L111.621 44.0771H113.071L115.903 49.8976H114.363L113.796 48.6503H113.819ZM113.354 47.6318L112.346 45.4075L111.337 47.6318H113.343H113.354Z" fill="#F36F28"/>
        <path id="Vector_7" d="M127.743 44.0771V49.8976H126.542L123.381 46.3637V49.8976H121.931V44.0771H123.143L126.293 47.611V44.0771H127.743Z" fill="#F36F28"/>
        <path id="Vector_8" d="M138.778 48.6503H135.832L135.266 49.8976H133.759L136.591 44.0771H138.042L140.874 49.8976H139.333L138.767 48.6503H138.778ZM138.313 47.6318L137.305 45.4075L136.297 47.6318H138.302H138.313Z" fill="#F36F28"/>
        <path id="Vector_9" d="M151.082 46.8941H152.419V49.2535C151.717 49.7316 150.765 49.9914 149.859 49.9914C147.865 49.9914 146.403 48.7338 146.403 46.9876C146.403 45.2415 147.876 43.9839 149.893 43.9839C151.003 43.9839 151.932 44.3373 152.544 44.9921L151.604 45.7924C151.139 45.3454 150.607 45.1376 149.961 45.1376C148.726 45.1376 147.887 45.8963 147.887 46.998C147.887 48.0998 148.726 48.8585 149.949 48.8585C150.346 48.8585 150.72 48.7961 151.094 48.609V46.9045L151.082 46.8941Z" fill="#F36F28"/>
        <path id="Vector_10" d="M164.044 48.8166V49.8976H159.138V44.0771H163.931V45.1581H160.6V46.4261H163.546V47.4759H160.6V48.8166H164.055H164.044Z" fill="#F36F28"/>
        <path id="Vector_11" d="M176.337 49.8979V46.4057L174.456 49.2847H173.799L171.941 46.4888V49.9083H170.57V44.0879H171.782L174.15 47.7049L176.484 44.0879H177.685L177.708 49.9083H176.337V49.8979Z" fill="#F36F28"/>
        <path id="Vector_12" d="M189.479 48.8166V49.8976H184.573V44.0771H189.366V45.1581H186.035V46.4261H188.981V47.4759H186.035V48.8166H189.49H189.479Z" fill="#F36F28"/>
        <path id="Vector_13" d="M201.817 44.0771V49.8976H200.616L197.455 46.3637V49.8976H196.005V44.0771H197.217L200.367 47.611V44.0771H201.817Z" fill="#F36F28"/>
        <path id="Vector_14" d="M209.987 45.1789H207.959V44.0771H213.488V45.1789H211.46V49.8976H209.998V45.1789H209.987Z" fill="#F36F28"/>
      </g>
      <path id="Vector_15" d="M77.2365 11.0983C65.4873 8.02178 56.7179 12.1688 55.6075 12.6781C54.9731 11.7011 54.2253 10.7969 53.3869 9.96539C50.1012 6.73297 45.4219 4.70622 40.2214 4.70622C33.9786 4.70622 28.4722 7.63722 25.2545 12.0753C24.4388 13.1978 23.7703 14.4242 23.2718 15.7235C23.1585 15.7131 23.0452 15.7131 22.9319 15.7131C20.8698 15.7131 18.8984 16.0353 17.0629 16.6173C10.0384 18.8415 5.00785 24.9634 5.00785 32.1558C5.00785 40.8344 12.3383 47.9437 21.6289 48.5569C21.1644 48.5881 20.6999 48.5985 20.224 48.5985C9.05265 48.5985 0 40.294 0 30.0459C0 19.7978 8.37285 12.1273 18.9551 11.5348C22.626 4.70622 30.2964 0.00830078 39.1677 0.00830078C46.5889 0.00830078 53.1716 3.29268 57.2617 8.36477C59.5504 7.63722 70.5971 4.86212 77.2365 11.0983Z" fill="#F36F28"/>
      <path id="Vector_16" d="M214.204 37.2695H207.451L186.887 13.4785H193.651L214.204 37.2695Z" fill="#45484A"/>
      <path id="Vector_17" d="M205.004 24.2255L214.203 13.4785H207.451L201.627 20.3175L205.004 24.2255Z" fill="#F36F28"/>
      <path id="Vector_18" d="M197.968 24.4546L186.887 37.2699H193.651L201.344 28.3626L197.968 24.4546Z" fill="#45484A"/>
      <path id="Vector_19" d="M183.987 13.4785H178.197V37.2695H183.987V13.4785Z" fill="#45484A"/>
      <path id="Vector_20" d="M183.987 6.01611H178.197V10.4022H183.987V6.01611Z" fill="#F36F28"/>
      <path id="Vector_21" d="M42.6568 13.4787H31.6667C29.2534 13.4787 27.3047 15.2664 27.3047 17.4802V47.8192C30.4544 47.8192 33.015 45.4702 33.015 42.5808V37.2696H42.6681C47.6307 37.2696 51.6528 33.5799 51.6528 29.0275V21.7104C51.6528 17.158 47.6307 13.4683 42.6681 13.4683L42.6568 13.4787ZM45.9312 28.7988C45.9312 31.3973 43.6425 33.4968 40.81 33.4968H32.8337V17.6361H40.81C43.6425 17.6361 45.9312 19.7356 45.9312 22.334V28.7988Z" fill="#45484A"/>
      <path id="Vector_22" d="M111.554 37.2702H122.544C124.958 37.2702 126.906 35.4825 126.906 33.2687V2.92969C123.757 2.92969 121.196 5.27865 121.196 8.16807V13.4792H111.543C106.58 13.4792 102.558 17.1689 102.558 21.7213V29.0385C102.558 33.5909 106.58 37.2806 111.543 37.2806L111.554 37.2702ZM108.28 21.95C108.28 19.3516 110.569 17.2521 113.401 17.2521H121.377V33.1128H113.401C110.569 33.1128 108.28 31.0132 108.28 28.4148V21.95Z" fill="#45484A"/>
      <path id="Vector_23" d="M55.2446 37.2695H61.2835V21.0243C61.2835 19.1534 62.9377 17.636 64.9771 17.636C68.5007 17.636 71.3559 15.7755 71.3559 13.4785H63.5948C58.9835 13.4785 55.2446 16.9084 55.2446 21.1386V37.2695Z" fill="#45484A"/>
      <path id="Vector_24" d="M88.5209 13.4785H81.0544C75.5594 13.4785 71.1067 17.5632 71.1067 22.6041V28.1439C71.1067 33.1848 75.5594 37.2695 81.0544 37.2695H88.5209C94.0159 37.2695 98.4686 33.1848 98.4686 28.1439V22.6041C98.4686 17.5632 94.0159 13.4785 88.5209 13.4785ZM93.0189 28.0504C93.0189 30.7423 90.6396 32.925 87.7051 32.925H81.8702C78.9357 32.925 76.5564 30.7423 76.5564 28.0504V22.6977C76.5564 20.0057 78.9357 17.823 81.8702 17.823H87.7051C90.6396 17.823 93.0189 20.0057 93.0189 22.6977V28.0504Z" fill="#45484A"/>
      <path id="Vector_25" d="M160.239 17.8854V31.1061C160.239 34.5048 163.241 37.2695 166.958 37.2695H175.432C175.432 34.8478 173.291 32.8834 170.651 32.8834C168.011 32.8834 165.87 30.919 165.87 28.4973V17.8958H173.506V17.4177C173.506 15.2454 171.592 13.4889 169.224 13.4889H165.87V5.63135C162.766 5.63135 160.25 7.93873 160.25 10.7866V13.4785H154.88V14.4867C154.88 16.368 156.534 17.8854 158.585 17.8854H160.25H160.239Z" fill="#45484A"/>
      <path id="Vector_26" d="M147.515 13.4785H131.551V14.0086C131.551 16.1289 133.42 17.8438 135.731 17.8438H148.41V22.9263H135.879C132.888 22.9263 130.463 25.1505 130.463 27.8945V32.3014C130.463 35.0453 132.888 37.2695 135.879 37.2695H151.321C152.908 37.2695 154.199 36.0846 154.199 34.6295V19.6211C154.199 16.2328 151.208 13.4785 147.503 13.4785H147.515ZM148.478 33.5486H138.779C137.182 33.5486 135.879 32.3637 135.879 30.8878V29.1521C135.879 27.6866 137.17 26.4913 138.779 26.4913H148.478V33.5382V33.5486Z" fill="#45484A"/>
    </g>
  </svg>
</template>
